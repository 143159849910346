<template>
  <div v-if="rounds_by_pk">
    <b-row>
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          class="calc"
          title="Dados cadastrais"
        >

          <b-card-text><b>Id:</b> {{ rounds_by_pk.id }}</b-card-text>
          <b-card-text><b>Codigo:</b> {{ rounds_by_pk.code }}</b-card-text>
          <b-card-text><b>creada:</b> {{ rounds_by_pk.created_at }}</b-card-text>
          <b-card-text><b>ultima actualizacion:</b> {{ rounds_by_pk.updated_at }}</b-card-text>
          <b-card-text><b>Estado Actual:</b> {{ rounds_by_pk.status }}</b-card-text>

        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          class="calc"
          title="Apuestas"
        >
          <b-card-text><b>Apostado:</b> {{ rounds_by_pk.bets_aggregate.aggregate.sum.amount }}</b-card-text>
          <b-card-text><b>Premios:</b> {{ rounds_by_pk.winners_aggregate.aggregate.sum.win_value }}</b-card-text>
          <b-card-text><b>Bola Resultado:</b> {{ rounds_by_pk.result_ball }}</b-card-text>
          <b-card-text><b>Numeros Especiales:</b>
            <ul>
              <li
                v-for="special in rounds_by_pk.special_numbers"
                :key="special.id"
              >
                {{ special.number }} (X{{ special.factor }})
              </li>
            </ul>

          </b-card-text>

        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          class="calc"
          title="Acciones"
        >
          <ul>
            <li
              v-for="config in rounds_by_pk.configurations"
              :key="config.id"
            >
              {{ config.key }} = {{ config.value }}
            </li>

          </ul>
          <b-card-text v-if="rounds_by_pk.status != 'ENDED' ">

            <b-form-group v-if="rounds_by_pk.status == 'STARTED'">
              <label for="group-type">Numero Ganador</label>
              <b-form-input
                v-model="ball"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
            <b-button
              size="sm"
              variant="outline-primary"
              class="btn-tour-skip mr-1"
              @click="next()"
            >
              <span class="mr-25 align-middle">Continuar</span>
              <feather-icon
                icon="NextIcon"
                size="12"
              />
            </b-button>

          </b-card-text>
          <b-card-text v-if="rounds_by_pk.status == 'ENDED' ">
            Ronda Finalizada
            <b-button
              size="sm"
              variant="outline-primary"
              class="btn-tour-skip mr-1"
              @click="next()"
            >
              <span class="mr-25 align-middle">Proxima ronda</span>
              <feather-icon
                icon="NextIcon"
                size="12"
              />
            </b-button></b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <div>

      <bet-List :round-id="rounds_by_pk.id" />

      <winner-list :round-id="rounds_by_pk.id" />

    </div>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BButton, BCardText, BLink, BFormInput, BCol, BRow, BModal,
} from 'bootstrap-vue'

import WinnerList from '@/components/roulette/WinnerList.vue'
import BetList from '@/components/roulette/BetList.vue'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import { randomInt } from 'crypto'

export default {
  components: {
    BButton,
    BTab,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    WinnerList,
    BetList,
    BRow,
    BTabs,
    BModal,
    BLink,
    underscore: _,
    LogoLoaderAnimation,
  },
  data() {
    return {
      roundId: this.$route.params.roundId,
      rounds_by_pk: null,
      ball: 0,

    }
  },
  apollo: {
    $subscribe: {

      the_round: {
        query: gql`
      subscription rounds_by_pk($id: Int!) {
  rounds_by_pk(id: $id) {
    id
    code
    configurations {
      key
      value
    }
    special_numbers{
      id
      number
      factor
    }
    created_at
    updated_at
    result_ball
    status
    winners_aggregate {
      aggregate {
        sum {
          win_value
        }
      }
      nodes {
        bet_id
        player {
          last_nickname
          casino {
            id
            name
          }
          casino_player_id
        }
        win_value
      }
    }
    bets_aggregate(where: {status: {_in: ["CONFIRMED","PENDING"]}}) {
      nodes {
        id
        numbers {
          number
        }
        created_at
        session_id
        status
        amount
      }
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}


`,
        client: 'chatClient',
        variables() {
          return {
            id: this.$route.params.roundId,
          }
        },
        result({ data }) {
          this.rounds_by_pk = data.rounds_by_pk;
          if (this.rounds_by_pk.status === 'ENDED') {
            this.$router.push(`/round-single/${this.rounds_by_pk.id + 1}`)
          }
        },
      },
    },
  },
  watch: {

  },
  methods: {
    next() {
      // const ball = Math.floor(Math.random() * 36)
      // eslint-disable-next-line no-undef
      fetch(`${config.VUE_CONFIG_ROULETTE_GAMELOGIC}/round/auto?ball=${this.ball}`,
        { method: 'POST', mode: 'no-cors' }).then(() => {
        if (this.rounds_by_pk.status === 'ENDED') {
          this.$router.push(`/round-single/${this.rounds_by_pk.id + 1}`)
        }

        // this.$apollo.queries.rounds_by_pk.refetch()
      })
    },
  },
}
</script>

<style>

</style>
